import '@fontsource-variable/oswald';
import '@fontsource-variable/playfair-display';
import '@fontsource/acme';
import "@fontsource/great-vibes"; // Defaults to weight 400
import "@fontsource/great-vibes/400.css"; // Specify weight
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from "@mui/material";
import { LicenseInfo } from '@mui/x-data-grid-pro';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import './App.css';
import { setStateVariable, updateStateVariables } from './AppAction';
import AboutPage from './Components/AboutComponent/AboutPage';
import { CARVING_SETSTATE } from './Components/CarvingComponent/CarvingActionType';
import CarvingPage from './Components/CarvingComponent/CarvingPage';
import ContactPage from './Components/ContactComponent/ContactPage';
import GalleryPage from './Components/GallerComponent/GalleryPage';
import ServicePage from './Components/ServiceComponent/ServicePage';
import WeddingPage from './Components/WeddingComponent/WeddingPage';



// import { history } from "./history";
// const history = createBrowserHistory();
// const history = useHistory();

LicenseInfo.setLicenseKey(  ////MATERIAL UI DATA-GRID-PRO LICENSE KEY
  'e711be1ae5c2f17995619ed4b49b8791Tz00NTc0MyxFPTE2ODY5MTU3ODEyNTcsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
);


class App extends React.Component<Props> {

  componentDidMount() {
    const { app } = this.props;

    document.title = app.appTitle;
  }

  shouldComponentUpdate() {
    return true;
  }

  handleDrawerToggle = () => {
    const { setStateVariable, updateStateVariables } = this.props;
    // setStateVariable(APP_SETSTATE, "isShowBackdrop", true);
    setStateVariable(CARVING_SETSTATE, "isDrawerOpen", true);
  }

  handleCloseDrawerToggle = () => {
    console.log("Test");
    const { setStateVariable, updateStateVariables } = this.props;
    // setStateVariable(APP_SETSTATE, "isShowBackdrop", true);
    setStateVariable(CARVING_SETSTATE, "isDrawerOpen", false);
  }


  handleNavigation = async (item: any) => {
    const { history, setStateVariable } = this.props;
    setStateVariable(CARVING_SETSTATE, "isDrawerOpen", false);
    history.push(item);
  }

  onClickHome = () => {
    // const { history } = this.props;
    // if (history.location.pathname === "/") {
    //   history.push('/home');
    // }
  }


  navBar = () => {
    const { carving } = this.props;
    // const container = window !== undefined ? () => document.body : undefined;
    return (
      <div className="navigationBarClass">
        {/* <div > */}
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {/* <Box>Fancy Carving & Catering</Box> */}
          <AppBar component="nav">
            <Toolbar className="toolbar">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                // edge="start"
                edge="end"
                onClick={this.handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>

              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                className="alignItemLeftJustify"
                onClick={() => this.handleNavigation('home')}>
                Fancy Carvings & Catering
              </Typography>

              <Box sx={{ display: { xs: 'none', sm: 'block' } }} className="alignItemRight">
                {carving.navigationList.map((item: any) => (

                  <Button key={item} sx={{ color: '#fff' }} onClick={() => this.handleNavigation(item)} className="linkButtonsClass">
                    {item}
                  </Button>
                ))}
              </Box>
            </Toolbar>
          </AppBar>

          <Box component="nav"
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box' },
            }}

          >
            <Drawer
              open={carving.isDrawerOpen}
              variant="temporary"
              // open={false}
              onClose={this.handleCloseDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box' },
              }}
              anchor='right'
              className="mobileDraweerClass"
            >
              <Typography variant="h6" sx={{ my: 2 }} className="mobileHeaderFont">
                Fancy Carvings <br />
                & <br />
                Catering
              </Typography>
              <Divider />
              <List className="openDrawerClass">
                {carving.navigationList.map((item: any) => (
                  <ListItem key={item} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => this.handleNavigation(item)}>
                      <ListItemText primary={String(item).toUpperCase()}  className="listItemClass"/>
                    </ListItemButton>
                  </ListItem>

                ))}
              </List>
              {/* </Box> */}
            </Drawer>
          </Box>


        </Box>
      </div>
    );
  }
  render() {
    const { app } = this.props;


    const routes = (
      <Switch>

        <Route component={CarvingPage} exact path='/' />

        <Route component={CarvingPage} exact path='/home' />

        <Route component={GalleryPage} exact path='/gallery' />

        <Route component={AboutPage} exact path='/about' />

        <Route component={ServicePage} exact path='/services' />

        <Route component={ContactPage} exact path='/contact us' />

        <Route component={WeddingPage} exact path='/wedding' />

        {/* <Route component={APDocumentPage} exact path='/ap' />

        <Route component={PRDocumentPage} exact path='/pr' />

        <Route component={HRDocumentPage} exact path='/hr' /> */}


      </Switch>

    );

    // const routes = (
    //   <HashRouter>
    //     <Router history={history}
    //     >
    //       {/* <Router> */}
    //       <Switch>

    //         {/* <Route component={CarvingPage} exact path='/' /> */}

    //         <Route component={CarvingPage} exact path='/Home' />

    //         <Route component={GalleryPage} exact path='/Gallery' />

    //         <Route component={AboutPage} exact path='/About' />

    //         <Route component={ServicePage} exact path='/Services' />

    //         <Route component={ContactPage} exact path='/Contact us' />


    //       </Switch>
    //     </Router>
    //   </HashRouter>

    // );

    return (
      <div className="App" >
        {/* <div > */}
        <div className="pageBackgroundColor">

          {/* <DrawerAppBar></DrawerAppBar> */}
          {/* <HeaderPage /> */}

          {/* <Backdrop open={app.isShowBackdrop} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
          </Backdrop> */}

          <this.navBar />

          <header className="App-header">
            {/* <header> */}
            {routes}
          </header>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const app = state.app;
  const carving = state.carving;
  return {
    app: app,
    carving: carving,
  };
};

const mapDispatchToProps = {
  setStateVariable,
  updateStateVariables,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  history: any;
};


export default withRouter(connector(App));